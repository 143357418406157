import * as React from 'react';
import {
  Box,
  Link,
  Paper,
  Typography,
  Button,
  Container,
  styled,
} from '@mui/material';
import { motion } from 'framer-motion';

// Cria um componente de texto estilizado utilizando o 'styled' do Material UI
const Text = styled(Typography)({
  color: 'white', // Define a cor do texto como branco
  fontWeight: 500, // Define o peso da fonte
  letterSpacing: '.15em', // Define o espaçamento entre as letras
  whiteSpace: 'pre-wrap', // Mantém espaços e quebras de linha
});

// Cria um componente de botão de texto estilizado
const TextButton = styled(Link)({
  color: 'white', // Define a cor do texto
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif', // Define a fonte
  fontWeight: 700, // Define o peso da fonte
  letterSpacing: '.15em', // Define o espaçamento entre as letras
  whiteSpace: 'pre-wrap', // Mantém espaços e quebras de linha
  alignSelf: 'baseline', // Alinha-se à linha de base
  position: 'relative', // Define o posicionamento relativo para uso de pseudo-elementos
  overflow: 'hidden', // Oculta conteúdo que ultrapassa o tamanho do elemento
  animation: 'glow cubic-bezier(0.4, 0, 0.25, 0.6) 20s infinite',
  textDecoration: 'none',
  '&:hover': {
    cursor: 'pointer', // Muda o cursor para indicar clicável ao passar o mouse
  },
  '&::after': {
    content: '""', // Cria um pseudo-elemento vazio
    position: 'absolute',
    bottom: 0, // Posiciona na parte inferior
    left: 0, // Alinha à esquerda
    width: '100%', // Largura total do elemento
    height: '0.05em', // Altura da linha inferior
    backgroundColor: '#ffffff', // Cor da linha
    opacity: 1, // Define a opacidade total
    transform: 'translate3d(-101%, 0, 0)', // Move a linha para fora da tela
    transition: 'cubic-bezier(0.4, 0, 0.25, 0.6) 500ms', // Transição suave
  },
  '&:hover::after, &:focus::after': {
    transform: 'translate3d(0 , 0, 0)', // Move a linha para a posição original ao hover ou foco
  },
  '@keyframes glow': {
    '0%': {},
    '12%': {
      textShadow: '0 0 5px #fffe',
    },
    '24%': {
      textShadow: '0 0 5px #fff0',
    },
    '100%': {},
  },
});

// Cria um componente de botão estilizado com base no componente Button do Material-UI
const StyledButton = styled(Button)({
  color: 'white', // Cor do texto
  borderRadius: '8px', // Bordas arredondadas
  fontSize: '.95em', // Tamanho da fonte
  letterSpacing: '.15em', // Espaçamento entre letras
  fontWeight: 700, // Peso da fonte em negrito
  textShadow: '-2px -2px 2px rgba(243, 244, 255, 0.1)', // Efeito de sombra
  transition: 'cubic-bezier(0.4, 0, 0.25, 0.6) 850ms',
  '&:hover': {
    backgroundColor: '#f3f4ff00', // Cor de fundo ao passar o mouse
    textShadow: '0 0 15px #fffa',
    scale: 1.1,
  },
});

// Componente principal que renderiza a seção de projetos
export default function Projects() {
  const [Projetos, setProjetos] = React.useState([]);
  const [viewItens, setViewItens] = React.useState(4);
  const [isLoading, setIsLoading] = React.useState(false);

  // Função assíncrona para carregar mais projetos
  const handleLoadMore = async () => {
    // Define o estado de carregamento para verdadeiro
    setIsLoading(true);

    // Define os proximos projetos a serem exibidos
    const nextProjects = Projetos.slice(viewItens, viewItens + 4);

    // Pré-carrega as imagens dos proximos projetos
    await preloadImages(nextProjects.map((project) => project.image));

    // Após o carregamento, aumenta a visualização dos projetos em 4
    setViewItens((prevViewItens) => prevViewItens + 4);

    // Define o estado de carregamento para falso
    setIsLoading(false);
  };

  // Função para pré-carregar as imagens dos projetos
  const preloadImages = (imagesUrls) => {
    return Promise.all(
      imagesUrls.map((url) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = url;
          img.onload = resolve;
          img.onerror = reject;
        });
      }),
    );
  };

  React.useEffect(() => {
    const fetchProjetos = async () => {
      try {
        const response = await fetch(
          'https://r2storage.zyvoxi.com/Jsons/Projects.json',
        );

        const data = await response.json();

        setProjetos(data);
      } catch (error) {
        console.error('Erro ao carregar os projetos:', error);
      }
    };
    fetchProjetos();
  }, []);

  const ProjectsAnimation = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <Box pt={10}>
      {/* Padding superior para espaçamento */}
      <Text
        variant='h4' // Define a variante tipográfica
        align='center' // Alinha o texto ao centro
        sx={{
          fontWeight: 700, // Peso da fonte
          fontSize: { xs: '2.15em', sm: '2.35em' }, // Tamanho da fonte responsivo
        }}
      >
        Projetos
        {/* Título da seção */}
      </Text>
      <Container
        maxWidth={'xl'}
        sx={{
          display: 'flex', // Utiliza flexbox
          flexDirection: 'column', // Direção dos itens em coluna
          mt: { xs: 7, sm: 5 }, // Margem superior responsiva
        }}
      >
        {Projetos.slice(0, viewItens).map((project, index) => (
          <motion.div
            key={index}
            variants={ProjectsAnimation}
            initial='hidden'
            animate='visible'
            transition={{ duration: 0.8 }}
          >
            <Paper
              elevation={24}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column-reverse', lg: 'row' },
                mb: 3.5,
                p: 2,
                height: '100%',
                bgcolor: '#e3e4ff05',
                borderRadius: 3,
                boxShadow: 0,
              }}
            >
              <Paper
                elevation={24}
                component={project.href ? Link : 'div'}
                href={project.href ? project.href : undefined}
                target={project.href ? '_blank' : undefined}
                sx={{
                  display: 'flex',
                  my: 'auto',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                  borderRadius: 3,
                  bgcolor: 'transparent',
                  overflow: 'hidden',
                }}
              >
                <img
                  src={project.image} // Fonte da imagem do projeto
                  alt={project.label} // Texto alternativo para acessibilidade
                  style={{
                    width: '100%', // Largura total da imagem
                    height: '100%', // Altura total da imagem
                    objectFit: 'cover', // Ajusta a imagem para cobrir o espaço sem distorcer
                  }}
                />
              </Paper>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  maxWidth: { xs: '100%', lg: '400px' },
                  pl: { xs: 0, lg: 2 },
                }}
              >
                <TextButton
                  component={project.href ? 'a' : 'button'}
                  href={project.href ? project.href : undefined}
                  target={project.href ? '_blank' : undefined}
                  sx={{
                    alignSelf: 'center',
                    my: { xs: 0, lg: 1 },
                    fontSize: { xs: '1.35em', sm: '1.50em' },
                  }}
                >
                  {project.label}
                </TextButton>
                <Text
                  sx={{
                    textAlign: { xs: 'center', lg: 'left' },
                    fontSize: '20px',
                    mt: { xs: 1, lg: 0 },
                    mb: 5,
                    p: 0.5,
                  }}
                >
                  {project.description}
                </Text>
                <Box mt='auto' mb={{ xs: 1.5, lg: 0 }}>
                  <Text
                    align='right'
                    sx={{
                      fontSize: '14px',
                      color: '#f3f4ff3e',
                    }}
                  >
                    {project.date}
                  </Text>
                </Box>
              </Box>
            </Paper>
          </motion.div>
        ))}
        {viewItens < Projetos.length && (
          <Box mx={'auto'}>
            <StyledButton
              disableRipple={true}
              aria-label='Carregar mais'
              onClick={() => handleLoadMore()}
              disabled={isLoading}
            >
              {isLoading ? '...' : 'Carregar mais'}
            </StyledButton>
          </Box>
        )}
      </Container>
    </Box>
  );
}
