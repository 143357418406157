import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  LinearProgress,
  Container,
  styled,
} from '@mui/material';
import {
  CIcon,
  ReactIcon,
  CssIcon,
  HtmlIcon,
  GitIcon,
  JsIcon,
  NodeJsIcon,
} from '../../../assets/Icons/SkillsIcon';
import PropTypes from 'prop-types';

// Cria um componente de texto estilizado usando o `styled` do Material UI
const Text = styled(Typography)({
  color: 'white', // Define a cor do texto como branco
  fontWeight: 700, // Define o peso da fonte como 700 (negrito)
  letterSpacing: '.15em', // Define o espaçamento entre as letras
  whiteSpace: 'pre-wrap', // Preserva quebras de linha e espaços em branco
});

// Lista de habilidades com seus respectivos ícones e valores
const SkillsList = [
  { label: 'JavaScript', value: 84, Icon: JsIcon }, // Habilidade em JavaScript
  { label: 'React', value: 85, Icon: ReactIcon }, // Habilidade em React
  { label: 'Node.js', value: 80, Icon: NodeJsIcon }, // Habilidade em Node.js
  { label: 'HTML', value: 92, Icon: HtmlIcon }, // Habilidade em HTML
  { label: 'CSS', value: 86, Icon: CssIcon }, // Habilidade em CSS
  { label: 'C/C++', value: 66, Icon: CIcon }, // Habilidade em C/C++
  { label: 'Git', value: 70, Icon: GitIcon }, // Habilidade em Git
];

// Hook personalizado para detectar se um elemento está visível na viewport
const useVisibility = (options) => {
  const [isVisible, setIsVisible] = useState(false); // Estado para controlar a visibilidade
  const ref = useRef(); // Referência ao elemento DOM

  useEffect(() => {
    const currentRef = ref.current; // Obtém a referência atual do elemento
    // Cria um observador para detectar interseção (visibilidade) do elemento
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true); // Se o elemento está visível, atualiza o estado
      }
    }, options);

    if (currentRef) {
      observer.observe(currentRef); // Inicia a observação do elemento
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef); // Interrompe a observação ao desmontar
      }
    };
  }, [options]);

  return [ref, isVisible]; // Retorna a referência e o estado de visibilidade
};

// Componente que representa cada item de habilidade
const SkillItem = ({ skill }) => {
  const [ref, isVisible] = useVisibility({ threshold: 0.6 }); // Usa o hook personalizado
  const IconComponent = skill.Icon; // Componente do ícone da habilidade

  return (
    <Box
      ref={ref} // Referência necessária para o hook de visibilidade
      sx={{
        display: 'flex', // Exibe os itens em flex
        alignItems: 'center', // Alinha os itens ao centro verticalmente
        mb: 5, // Margin-bottom com espaçamento de 5
        transform: isVisible ? 'translateX(0)' : 'translateX(-100px)', // Animação de entrada
        opacity: isVisible ? 1 : 0, // Opacidade para efeito de fade-in
        transition: 'cubic-bezier(0.4, 0, 0.25, 0.6) 650ms', // Timing da transição
      }}
    >
      <Box display='flex' flexDirection='row' alignItems='center' pr={2}>
        <Box
          sx={{
            textAlign: 'center', // Centraliza o texto e o ícone
            position: 'relative', // Necessário para o posicionamento do :after
            display: 'inline-block', // Ajusta o tamanho do Box ao conteúdo
            '&:after': {
              content: '""',
              position: 'absolute',
              top: '-20%', // Expande além do Box para que a sombra fique maior
              left: '-20%',
              right: '-20%',
              bottom: '-20%',
              zIndex: -1, // Coloca a sombra atrás do conteúdo
              borderRadius: '50%', // Torna a sombra arredondada
              opacity: 0, // 0% de opacidade inicial
              backgroundImage:
                'radial-gradient(circle, rgba(243, 244, 255, 0.3), rgba(243, 244, 255, 0.2), rgba(243, 244, 255, 0.1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))', // Gradiente radial
              transition: 'cubic-bezier(0.4, 0, 0.25, 0.6) 450ms',
              scale: 1.3,
            },
            '&:hover:after': {
              opacity: 1,
            },
          }}
        >
          <IconComponent
            aria-label={skill.label}
            sx={{
              zIndex: 1,
              fontSize: { xs: '3em', md: '4.5em' },
            }}
          />
          {/* Renderiza o ícone da habilidade */}
        </Box>

        <Box
          width={100}
          sx={{ textAlign: { xs: 'center', lg: 'left' }, pl: 2 }}
        >
          <Text>{skill.label}</Text> {/* Exibe o nome da habilidade */}
        </Box>
      </Box>
      <LinearProgress
        variant='determinate' // Barra de progresso determinada
        value={skill.value} // Valor da habilidade
        aria-label={`${skill.value}`} // Label para acessibilidade
        sx={{
          flexGrow: 1, // Faz a barra ocupar todo o espaço disponível
          bgcolor: '#30304099', // Cor de fundo da barra
          '& .MuiLinearProgress-bar': {
            backgroundColor: '#9090A0', // Cor da barra de progresso
          },
        }}
      />
    </Box>
  );
};

// Define os tipos das propriedades esperadas pelo SkillItem
SkillItem.propTypes = {
  skill: PropTypes.shape({
    label: PropTypes.string.isRequired, // label deve ser uma string
    value: PropTypes.number.isRequired, // value deve ser um número
    Icon: PropTypes.elementType.isRequired, // Icon deve ser um componente React
  }).isRequired,
};

// Componente principal que renderiza a seção de habilidades
export default function Skills() {
  return (
    <Box
      sx={{
        pt: 10, // Padding-top para espaçamento superior
        height: '100%', // Altura total
        width: '100%', // Largura total
        maxWidth: '100vw', // Largura máxima não excede a viewport
      }}
    >
      <Text
        align='center' // Alinha o texto ao centro
        sx={{
          fontWeight: 700, // Peso da fonte (negrito)
          fontSize: { xs: '2.15em', sm: '2.35em' }, // Tamanho da fonte responsivo
        }}
      >
        Habilidades
      </Text>
      <Container
        maxWidth={'xl'}
        sx={{
          mt: { xs: 7, sm: 5 }, // Margem superior responsiva
          display: 'flex', // Exibe os itens em flex
          flexDirection: 'column', // Direção da flexbox em coluna
          gap: 1.5, // Espaçamento entre os itens
        }}
      >
        {SkillsList.map((skill, index) => (
          <SkillItem key={index} skill={skill} /> // Renderiza cada SkillItem
        ))}
      </Container>
    </Box>
  );
}
