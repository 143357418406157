import * as React from 'react';
import { SvgIcon } from '@mui/material';

export default function ZyvoxiIcon(props) {
  return (
    <SvgIcon viewBox='0 0 300 300' {...props}>
      <metadata>Copyrigh © 2024 Zyvoxi. All rights reserved.</metadata>
      <g
        transform='translate(0.000000,300.000000) scale(0.100000,-0.100000)'
        fill='#000000'
        stroke='none'
      >
        <circle
          style={{ fill: '#ffffff', strokeWidth: 0.75 }}
          cx='118.46362'
          cy='185.78168'
          r='10'
          transform='matrix(9.9999998,0,0,-9.9999998,0,3000)'
        />
        <circle
          style={{ fill: '#ffffff', strokeWidth: 0.75 }}
          cx='172.29515'
          cy='164.0128'
          r='10'
          transform='matrix(9.9999998,0,0,-9.9999998,0,3000)'
        />
        <circle
          style={{ fill: '#ffffff', strokeWidth: 0.75 }}
          cx='123.30054'
          cy='110.06334'
          r='10'
          transform='matrix(9.9999998,0,0,-9.9999998,0,3000)'
        />
        <path
          style={{ fill: '#ffffff', strokeWidth: 0.762379 }}
          d='m 104.82715,163.76175 c -8.825632,-0.46822 -8.564219,0.0948 -4.0966,-8.82261 12.10818,-24.16796 31.56875,-42.71229 56.77934,-54.10609 8.2055,-3.708434 17.59758,-6.461542 18.60533,-5.453793 0.88132,0.88132 1.12961,3.891917 0.91065,11.041933 l -0.15388,5.02474 -4.38368,0.96211 c -19.56386,4.29379 -41.17364,22.04745 -55.03439,45.21388 -3.51903,5.88158 -4.23149,6.65376 -6.02187,6.52666 -1.11684,-0.0793 -4.08905,-0.25336 -6.6049,-0.38683 z'
          transform='matrix(7.5,0,0,-7.5,0,3000)'
        />
        <path
          style={{ fill: '#ffffff', strokeWidth: 0.762379 }}
          d='m 198.43692,150.73371 -6.26191,-5.88165 -0.21831,-26.11181 -0.21831,-26.111804 6.67082,3.18e-4 6.67082,3.18e-4 v 32.019928 c 0,17.61096 -0.0858,32.00787 -0.1906,31.99314 -0.10483,-0.0147 -3.00845,-2.67354 -6.45251,-5.90844 z'
          transform='matrix(7.5,0,0,-7.5,0,3000)'
        />
        <path
          style={{ fill: '#ffffff', strokeWidth: 0.762379 }}
          d='m 220.02472,153.42883 c -0.8431,-13.58364 -0.89902,-57.621014 -0.0741,-58.379617 0.46143,-0.424346 3.22005,-0.22957 7.04783,0.497621 l 6.28963,1.194886 v 25.77053 25.77052 h 16.60244 16.60244 l 3.21387,6.48022 3.21386,6.48023 h -26.20546 -26.20546 z'
          transform='matrix(7.5,0,0,-7.5,0,3000)'
        />
        <path
          style={{ fill: '#ffffff', strokeWidth: 0.762379 }}
          d='m 281.74987,156.34075 c -7.75108,-13.17021 -18.66654,-25.0528 -30.867,-33.60189 l -6.15912,-4.31581 v -8.31309 -8.3131 l 4.22211,1.86722 c 2.32217,1.02697 7.05201,3.71184 10.51076,5.96636 16.28376,10.6143 28.53078,24.70166 39.73362,45.70434 l 4.37152,8.19558 h -8.79029 -8.79029 z'
          transform='matrix(7.5,0,0,-7.5,0,3000)'
        />
        <path
          style={{ fill: '#ffffff', strokeWidth: 0.762379 }}
          d='m 91.438348,183.54281 1.222237,-6.67082 42.868085,-0.19694 42.86808,-0.19694 v 6.86776 6.86776 H 134.30643 90.21611 Z'
          transform='matrix(7.5,0,0,-7.5,0,3000)'
        />
        <path
          style={{ fill: '#ffffff', strokeWidth: 0.762379 }}
          d='m 220.32706,183.63052 -5.5e-4,-7.52091 42.78637,0.51189 c 23.5325,0.28154 43.07045,0.79614 43.41767,1.14357 0.69447,0.6949 2.64505,11.54305 2.15636,11.99264 -0.16747,0.15408 -20.11682,0.53069 -44.3319,0.83693 l -44.0274,0.55679 z'
          transform='matrix(7.5,0,0,-7.5,0,3000)'
        />
        <path
          style={{ fill: '#ffffff', strokeWidth: 0.762379 }}
          d='m 258.44658,211.94144 v -6.48022 h 25.23589 25.23589 l -0.54401,5.52725 c -0.29921,3.03998 -0.68891,5.682 -0.86601,5.87115 -0.86417,0.923 -10.87827,1.4393 -29.04931,1.49771 l -20.01245,0.0643 z'
          transform='matrix(7.5,0,0,-7.5,0,3000)'
        />
        <path
          style={{ fill: '#ffffff', strokeWidth: 0.762379 }}
          d='m 194.57371,307.06678 -2.50134,-0.30925 0.2142,-44.16816 0.2142,-44.16817 -50.82859,2.3e-4 -50.828584,2.3e-4 -0.250826,-5.68071 c -0.226196,-5.12291 -0.101105,-5.73056 1.273933,-6.18829 0.838617,-0.27917 26.654687,-0.67632 57.369037,-0.88255 l 55.84429,-0.37497 v 51.16245 51.16245 l -4.0025,-0.12201 c -2.20137,-0.0671 -5.12809,-0.26117 -6.50382,-0.43125 z'
          transform='matrix(7.5,0,0,-7.5,0,3000)'
        />
        <path
          style={{ fill: '#ffffff', strokeWidth: 0.762379 }}
          d='m 165.7917,301.22034 c -20.37326,-8.07531 -30.25129,-14.26521 -42.66745,-26.73683 -11.92055,-11.9738 -19.54422,-23.22554 -25.465084,-37.58379 l -2.275278,-5.51761 h 8.692792 8.6928 l 4.32582,8.45693 c 9.89733,19.34914 22.3768,31.54123 42.63925,41.65734 4.18424,2.089 9.93508,4.56488 12.77965,5.50195 l 5.17195,1.70377 -0.21648,7.74362 c -0.19826,7.0915 -0.3449,7.75899 -1.74125,7.92623 -0.83861,0.10045 -5.31014,-1.31778 -9.93672,-3.15161 z'
          transform='matrix(7.5,0,0,-7.5,0,3000)'
        />
        <path
          style={{ fill: '#ffffff', strokeWidth: 0.762379 }}
          d='m 219.56523,297.49035 v -8.70768 l 5.90844,-1.73931 c 15.56013,-4.58054 28.35208,-12.10262 39.56377,-23.26475 7.21391,-7.18202 14.7549,-17.76605 19.41561,-27.25044 l 2.52882,-5.14606 h 7.86528 c 6.4851,0 7.94688,0.21266 8.33031,1.21188 0.59865,1.56003 -6.66936,16.11148 -12.53179,25.09021 -10.46004,16.02027 -24.52303,28.54679 -41.72884,37.16967 -4.55921,2.28489 -22.2536,9.21934 -27.63624,10.83066 -1.70874,0.51152 -1.71536,0.4799 -1.71536,-8.19418 z'
          transform='matrix(7.5,0,0,-7.5,0,3000)'
        />
      </g>
    </SvgIcon>
  );
}
