import * as React from 'react';
import Easing from 'easing-js';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
import { Box, Divider, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import AppBar from './Components/AppBar';
import Hello from './Components/Hello';
import About from './Components/About';
import Skills from './Components/Skills';
import Projects from './Components/Projects';
import Contact from './Components/Contact';
import Footer from './Components/Footer';

export default function Portfolio() {
  // Define um estado `init` com valor inicial `false`
  const [init, setInit] = React.useState(false);

  const helloRef = React.createRef(); // Referência para a seção "Olá"
  const aboutRef = React.createRef(); // Referência para a seção "Sobre"
  const skillsRef = React.createRef(); // Referência para a seção "Habilidades"
  const projectsRef = React.createRef(); // Referência para a seção "Projetos"
  const contactRef = React.createRef();

  // Função que realiza a rolagem suave até a seção desejada
  const scrollToSection = (sectionRef) => {
    // Verifica se a referência fornecida é válida
    if (sectionRef.current) {
      // Calcula a posição vertical da seção alvo em relação ao topo da página
      const targetPosition =
        sectionRef.current.getBoundingClientRect().top + window.scrollY;

      // Obtém a posição vertical atual da janela de visualização
      const startPosition = window.scrollY;

      // Calcula a distância que precisa ser percorrida para chegar à seção alvo
      const distance = targetPosition - startPosition;
      const duration = 1500; // Define a duração da animação em milissegundos
      let startTime = null; // Tempo inicial da animação, será definido na primeira execução

      // Função de animação que é chamada a cada frame
      const animation = (currentTime) => {
        // Define o tempo inicial na primeira execução
        if (startTime === null) {
          startTime = currentTime;
        }

        // Calcula o tempo decorrido desde o início da animação
        const timeElapsed = currentTime - startTime;

        // Função de easing para suavizar a animação (easeInOutQuad)
        // Calcula a posição atual utilizando a função de easing
        const run = Easing.easeInOutQuad(
          timeElapsed,
          startPosition,
          distance,
          duration,
        );

        // Rola a janela para a posição calculada
        window.scrollTo(0, run);

        // Se a animação ainda não terminou, continua a chamar requestAnimationFrame
        if (timeElapsed < duration) {
          requestAnimationFrame(animation);
        } else {
          // Garante que a rolagem termine exatamente na posição alvo
          window.scrollTo(0, targetPosition);
        }
      };

      // Inicia a animação
      requestAnimationFrame(animation);
    }
  };

  // Função chamada quando as partículas são carregadas
  const particlesLoaded = (container) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(container); // Loga o container das partículas no console
    }
  };

  // Utiliza o hook `useMemo` para memorizar as opções das partículas
  const options = React.useMemo(
    () => ({
      background: {
        color: {
          value: '#0000000', // Define a cor de fundo das partículas
        },
      },
      fpsLimit: 24, // Limita a taxa de quadros por segundo a 120
      fullScreen: { enable: false }, // Desativa o modo de tela cheia
      interactivity: {
        events: {
          onClick: {
            enable: false, // Desativa a interação ao clicar
            mode: 'push',
          },
          onHover: {
            enable: false, // Desativa a interação ao passar o mouse
            mode: 'repulse',
          },
        },
        modes: {
          push: {
            quantity: 4, // Quantidade de partículas adicionadas no modo `push`
          },
          repulse: {
            distance: 200, // Distância de repulsão no modo `repulse`
            duration: 0.4, // Duração do efeito de repulsão
          },
        },
      },
      particles: {
        color: {
          value: '#ffffff', // Cor das partículas
        },
        links: {
          color: '#ffffff', // Cor das linhas que conectam as partículas
          distance: 180, // Distância máxima para conectar partículas
          enable: true, // Habilita as linhas entre partículas
          opacity: 0.5, // Opacidade das linhas
          width: 2, // Largura das linhas
        },
        move: {
          direction: 'random', // Direção do movimento das partículas
          enable: true, // Habilita o movimento das partículas
          outModes: {
            default: 'bounce', // Modo de saída das partículas ao atingir as bordas
          },
          random: false, // Movimento não é aleatório
          speed: 0.1, // Velocidade do movimento das partículas
          straight: false, // Movimento não é em linha reta
        },
        number: {
          density: {
            enable: true, // Habilita a densidade das partículas
          },
          value: 100, // Número de partículas
        },
        opacity: {
          value: 0.5, // Opacidade das partículas
        },
        shape: {
          type: 'circle', // Forma das partículas
        },
        size: {
          value: { min: 1, max: 5 }, // Tamanho das partículas varia entre 1 e 5
        },
      },
      detectRetina: true, // Habilita a detecção de retina para melhor qualidade em telas de alta definição
    }),
    [], // Dependências do `useMemo` (vazio para evitar recomputação)
  );

  // Efeito colateral que inicializa o motor de partículas
  React.useEffect(() => {
    initParticlesEngine(async (engine) => {
      // Carrega o pacote slim do `tsparticles`
      await loadSlim(engine);
    }).then(() => {
      // Após carregar, atualiza o estado `init` para `true`
      setInit(true);
    });
  }, []);

  // Se não estiver inicializado, não renderiza nada
  if (!init) return;

  // Renderiza o componente `Portfolio`
  return (
    <>
      <AppBar
        helloRef={helloRef}
        aboutRef={aboutRef}
        skillsRef={skillsRef}
        projectsRef={projectsRef}
        scrollToSection={scrollToSection}
      />
      <Box
        component={'section'}
        ref={helloRef} // Referência passada para o componente `Box`
        sx={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Particles
          id='tsparticles'
          particlesLoaded={particlesLoaded} // Função chamada quando as partículas são carregadas
          options={options} // Opções para configurar as partículas
        />
        <Hello aboutRef={aboutRef} scrollToSection={scrollToSection} />{' '}
        {/* Renderiza o componente `Hello` */}
      </Box>
      <Box component={'section'} ref={aboutRef}>
        {/* Referência para a seção `About` */}
        <About /> {/* Renderiza o componente `About` */}
      </Box>
      <Box component={'section'} ref={skillsRef}>
        {/* Referência para a seção `Skills` */}
        <Skills /> {/* Renderiza o componente `Skills` */}
      </Box>
      <Box component={'section'} ref={projectsRef}>
        {/* Referência para a seção `Projects` */}
        <Projects /> {/* Renderiza o componente `Projects` */}
      </Box>
      {process.env.NODE_ENV === 'xdevelopment' && (
        <>
          <Divider sx={{ mt: 3.5, bgcolor: '#303040' }} />
          <Box component={'section'} ref={contactRef}>
            <Contact />
          </Box>
        </>
      )}
      <Divider sx={{ mt: 3.5, bgcolor: '#303040' }} />
      {/* Adiciona um divisor estilizado */}
      <Box component={'footer'}>
        <Footer /> {/* Renderiza o componente `Footer` */}
      </Box>
      {process.env.NODE_ENV === 'development' && (
        <Box
          sx={{
            display: 'flex',
            position: 'fixed',
            bottom: 5,
            left: 5,
          }}
        >
          <WarningIcon
            sx={{ fontSize: 30, marginRight: 1, color: 'warning.light' }}
          />
          <Typography color='warning.light' fontWeight='bold' fontSize={'20px'}>
            Versão de Desenvolvimento
          </Typography>
        </Box>
      )}
    </>
  );
}
