import * as React from 'react';
import { Box, Container, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';

// Cria um componente estilizado chamado 'Text' baseado no componente 'Typography' do Material-UI
const Text = styled(Typography)({
  color: 'white', // Define a cor do texto como branco
  fontWeight: 500, // Define a espessura da fonte
  letterSpacing: '.15em', // Define o espaçamento entre letras
  whiteSpace: 'pre-wrap', // Mantém quebras de linha e espaços em branco
});

// Texto completo que será exibido com efeito de digitação
const fullText =
  'Olá, meu nome é Felipe, \nmas também sou conhecido \npor Zyvoxi ou Ivory';

// Componente funcional 'Hello' que recebe 'aboutRef' e 'scrollToSection' como props
export default function Hello({ aboutRef, scrollToSection }) {
  // Estado que armazena o texto atualmente exibido na tela
  /* const [displayedText, setDisplayedText] = React.useState(''); */

  // Estado que controla a visibilidade do cursor piscante
  const [showCursor, setShowCursor] = React.useState(true);

  // Estado que indica se a animação de digitação foi concluída
  /* const [typerFinished, setTyperFinished] = React.useState(false); */

  // Estado que indica se o Box está visível ou não
  const [isVisible, setIsVisible] = React.useState(true);

  // Referências para armazenar os intervalos dos timers
  const cursorBlinkIntervalRef = React.useRef(null);
  /* const typingIntervalRef = React.useRef(null); */
  /* const lastTimeRef = React.useRef(Date.now()); */

  // Hook de efeito que inicia a animação de digitação ao montar o componente
  React.useEffect(() => {
    /* setTyperFinished(false); // Reinicia o estado de conclusão da digitação
    let charIndex = 0; // Índice do caractere atual no texto
    let typingSpeed = 120; // Velocidade inicial (em milissegundos)

    // Função para ajustar a velocidade (aceleração/desaceleração)
    const adjustTypingSpeed = () => {
      const timeElapsed = Date.now() - lastTimeRef.current;
      if (timeElapsed > 1000 && typingSpeed > 60) {
        typingSpeed = 10; // Acelera após 1 segundo
      }
    }; */

    // Inicia um intervalo que simula a digitação do texto, com aceleração/desaceleração
    /* typingIntervalRef.current = setInterval(() => {
      if (charIndex < fullText.length) {
        const nextChar = fullText[charIndex]; // Obtém o próximo caractere do texto
        if (nextChar !== undefined) {
          setDisplayedText((prev) => prev + nextChar); // Atualiza o texto exibido
        }
        charIndex++; // Avança para o próximo caractere
        adjustTypingSpeed(); // Ajusta a velocidade conforme o tempo passa
      } else {
        // Se todos os caracteres foram exibidos, limpa o intervalo de digitação
        clearInterval(typingIntervalRef.current);

        // Define um atraso antes de indicar que a digitação foi concluída
        setTimeout(() => {
          setTyperFinished(true); // Marca que a animação de digitação terminou
        }, 1000);
      }
    }, typingSpeed); */

    // Inicia um intervalo que faz o cursor piscar a cada 800ms
    cursorBlinkIntervalRef.current = setInterval(() => {
      setShowCursor((prev) => !prev); // Alterna a visibilidade do cursor
    }, 800);

    const handleScroll = () => {
      // Oculta o Box se o scroll sair do topo
      const scrolled = window.scrollY >= 10;
      setIsVisible(!scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    // Função de limpeza que é executada ao desmontar o componente
    return () => {
      window.removeEventListener('scroll', handleScroll);
      /* clearInterval(typingIntervalRef.current); */ // Limpa o intervalo de digitação
      clearInterval(cursorBlinkIntervalRef.current); // Limpa o intervalo do cursor piscante
      /* setDisplayedText(''); */ // Reseta o texto exibido
    };
  }, []);

  return (
    <Box
      sx={{
        height: '100vh',
        backdropFilter: 'blur(4px)',
      }}
    >
      {/* Container principal */}
      <Container
        maxWidth={'xl'}
        sx={{
          display: 'flex',
          flexDirection: 'column', // Alinha os elementos em coluna
          pt: '30vh',
          textAlign: { xs: 'center', sm: 'left' }, // Alinha o texto
        }}
      >
        {/* Título principal para telas maiores */}
        <Text variant='h3' sx={{ display: { xs: 'none', sm: 'block' } }}>
          {fullText}
          {/* Cursor piscante */}
          <Box
            component={'span'}
            sx={{
              transition: 'cubic-bezier(0.4, 0, 0.25, 0.6) 300ms',
              color: showCursor ? 'white' : 'transparent', // Alterna entre visível e transparente
            }}
          >
            |
          </Box>
        </Text>
        {/* Título para telas menores */}
        <Text
          variant='h5'
          sx={{
            display: { xs: 'auto', sm: 'none' },
            whiteSpace: 'collapse', // Remove espaços em branco extras
          }}
        >
          {fullText}
          {/* Cursor piscante */}
          <Box
            component={'span'}
            sx={{
              transition: 'cubic-bezier(0.4, 0, 0.25, 0.6) 300ms',
              color: showCursor ? 'white' : 'transparent', // Alterna entre visível e transparente
            }}
          >
            |
          </Box>
        </Text>
        {/* Subtítulo para telas maiores, aparece após concluir a digitação */}
        <Text
          variant='h4'
          sx={{
            display: { xs: 'none', sm: 'block' },
            fontSize: '16px',
            my: 2.5, // Margem vertical
          }}
        >
          Sou um desenvolvedor front-end especializado em <br />
          React e JavaScript.
        </Text>
        {/* Subtítulo para telas menores */}
        <Text
          variant='subtitle1'
          sx={{
            display: { xs: 'block', sm: 'none' },
            mx: 1.8, // Margem horizontal
            my: 2, // Margem vertical
          }}
        >
          Sou um desenvolvedor front-end especializado em React e JavaScript.
        </Text>
      </Container>
      {/* Ícone interativo para rolar até a seção "Sobre" */}
      <Box
        onClick={() => {
          if (isVisible) scrollToSection(aboutRef);
        }} // Chama a função de rolagem ao clicar
        sx={{
          position: 'absolute',
          left: '50%',
          bottom: { xs: '18%', sm: '5%' },
          height: { xs: '55px', sm: '45px' },
          width: { xs: '30px', sm: '25px' },
          border: '1px solid white',
          borderRadius: 999,
          margin: 0,
          padding: 0,
          transition: 'cubic-bezier(0.4, 0, 0.25, 0.6) 650ms',
          opacity: isVisible ? '1' : '0',
          transform: isVisible ? 'translate(-50%, 0)' : 'translate(-50%, 100%)',
          '&:hover': {
            cursor: isVisible ? 'pointer' : 'auto', // Muda o cursor ao passar o mouse
            scale: 1.15, // Aumenta levemente o tamanho
          },
          // Elemento depois do box: scroll
          '&::after': {
            content: '""',
            width: '3.5px',
            height: '8px',
            borderRadius: 5,
            bgcolor: 'white',
            position: 'absolute',
            top: '10px',
            left: '50%',
            transform: 'translateX(-50%)',
            opacity: 1,
            animation: 'scroll cubic-bezier(0.25, 0.5, 0.75, 1) 5s infinite', // Animação do scroll
          },
          // Definição das animações
          '@keyframes scroll': {
            '0%': {
              transform: 'translateX(-50%) translateY(0)',
              opacity: 1,
            },
            '50%': {
              transform: 'translateX(-50%) translateY(12px)',
              opacity: 0,
            },
            '75%': {
              transform: 'translateX(-50%) translateY(0)',
              opacity: 0,
            },
            '100%': {
              transform: 'translateX(-50%) translateY(0)',
              opacity: 1,
            },
          },
        }}
      />
    </Box>
  );
}

// Define o tipo das props esperadas pelo componente 'Hello'
Hello.propTypes = {
  aboutRef: PropTypes.object.isRequired,
  scrollToSection: PropTypes.func.isRequired,
};
