import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Stack,
  styled,
  MenuItem,
  Divider,
} from '@mui/material';
import Drawer from '@mui/joy/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ZyvoxiIcon from '../../../assets/Icons/ZyvoxiIcon';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

// Cria um componente de texto estilizado com base no componente Typography do Material-UI
const TextButton = styled(Typography)({
  color: 'white', // Define a cor do texto como branco
  fontWeight: 500, // Define o peso da fonte
  letterSpacing: '.15em', // Define o espaçamento entre letras
  whiteSpace: 'pre-wrap', // Mantém as quebras de linha
  alignSelf: 'baseline', // Alinha-se à linha de base
  position: 'relative', // Necessário para o posicionamento dos pseudo-elementos
  overflow: 'hidden', // Esconde conteúdo que ultrapassa o tamanho do elemento
  '&:hover': {
    cursor: 'pointer', // Muda o cursor ao passar o mouse
  },
  '&::after': {
    content: '""', // Cria um pseudo-elemento vazio
    position: 'absolute',
    bottom: 0, // Posiciona na parte inferior
    left: 0, // Alinha à esquerda
    width: '100%', // Largura total do elemento
    height: '0.05em', // Altura mínima para criar uma linha
    backgroundColor: '#ffffff', // Cor da linha
    opacity: 1, // Totalmente opaco
    transform: 'translate3d(-100%, 0, 0)', // Move a linha para fora à esquerda
    transition: 'cubic-bezier(0.4, 0, 0.25, 0.6) 600ms',
  },
  '&:hover::after, &:focus::after': {
    transform: 'translate3d(0, 0, 0)', // Move a linha para a posição original ao hover
  },
});

// Cria um componente de botão estilizado com base no componente Button do Material-UI
const StyledButton = styled(Button)({
  color: 'white', // Cor do texto
  borderRadius: '8px', // Bordas arredondadas
  fontSize: '.95em', // Tamanho da fonte
  letterSpacing: '.15em', // Espaçamento entre letras
  fontWeight: 700, // Peso da fonte em negrito
  textShadow: '-2px -2px 2px rgba(243, 244, 255, 0.1)', // Efeito de sombra
  transition: 'cubic-bezier(0.8, 0.5, 0.25, 0.5) 250ms',
  '&:hover': {
    backgroundColor: '#f3f4ff10', // Cor de fundo ao passar o mouse
  },
});

// Cria um componente de item de menu estilizado
const StyledMenuItem = styled(MenuItem)({
  color: 'white', // Cor do texto
  borderRadius: '8px', // Bordas arredondadas
  fontSize: '.95em', // Tamanho da fonte
  letterSpacing: '.15em', // Espaçamento entre letras
  textTransform: 'uppercase', // Transforma o texto em maiúsculas
  fontWeight: 700, // Peso da fonte em negrito
  textShadow: '-1px -1px 2px rgba(243, 244, 255, 0.1)', // Efeito de sombra
  transition: 'cubic-bezier(0.8, 0.5, 0.25, 0.5) 250ms',
  '&:hover': {
    backgroundColor: '#f3f4ff10', // Cor de fundo ao passar o mouse
  },
});

const StyledIconButton = styled(IconButton)({
  transition: 'cubic-bezier(0.4, 0, 0.25, 0.6) 500ms',
  '&:hover': {
    bgcolor: 'transparent', // Cor de fundo transparente
    transform: 'scale(1.25)',
  },
});

// Array de objetos que representam os botões do menu e suas referências
const Buttons = [
  { id: 1, label: 'Olá', ref: 'helloRef' },
  { id: 2, label: 'Sobre', ref: 'aboutRef' },
  { id: 3, label: 'Habilidades', ref: 'skillsRef' },
  { id: 4, label: 'Projetos', ref: 'projectsRef' },
];

// Componente principal que representa a barra de navegação do aplicativo
export default function AppAppBar({
  helloRef,
  aboutRef,
  skillsRef,
  projectsRef,
  scrollToSection,
}) {
  // Estado que controla a abertura do menu em dispositivos móveis
  const [open, setOpen] = React.useState(false);

  // Função que alterna o estado de abertura do menu
  const toggleDrawer = () => {
    setOpen(!open); // Inverte o valor atual de 'open'
  };

  React.useEffect(() => {
    const handleScroll = () => {
      let scrollPos = window.scrollY; // Posição do scroll
      if (scrollPos < 0) scrollPos = 0; // Evita valores negativos

      const maxScroll = document.body.scrollHeight - window.innerHeight; // Máximo de scroll

      const hasScrollableContent = maxScroll > 0; // Verifica se há conteúdo que pode ser rolado

      // Verifica se o final da página está no topo da viewport
      const isEndInView = hasScrollableContent && scrollPos >= maxScroll;

      // Define as cores com base no estado `open`
      const startColor = !open ? [16, 15, 34] : [13, 14, 25];
      const endColor = !open ? [5, 1, 28] : [10, 8, 22];

      let currentColor = startColor; // Cor inicial padrão

      if (hasScrollableContent) {
        if (isEndInView) {
          currentColor = endColor; // Aplica a cor final se o final da página estiver no topo
        } else {
          // Suaviza a interpolação usando uma função de easing
          const percent = Math.min(
            1,
            Math.max(0, Math.pow(scrollPos / maxScroll, 0.7)),
          ); // Easing para suavizar no final
          currentColor = startColor.map((start, index) =>
            Math.round(start + percent * (endColor[index] - start)),
          );
        }
      } else {
        // Se não há scroll, sempre use startColor
        currentColor = startColor;
      }

      // Atualiza a meta tag
      const themeColorMeta = document.getElementById('themeColorMeta');
      if (themeColorMeta) {
        themeColorMeta.setAttribute(
          'content',
          `rgb(${currentColor[0]}, ${currentColor[1]}, ${currentColor[2]})`,
        );
      }
    };

    // Chama o manipulador de scroll imediatamente para ajustar a cor inicial
    handleScroll();

    // Registra o ouvinte de scroll
    window.addEventListener('scroll', handleScroll);

    // Limpa o ouvinte ao desmontar o componente ou atualizar dependências
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [open]);

  return (
    // Componente AppBar fixado no topo da tela com transparência
    <AppBar
      position='fixed'
      color='transparent'
      sx={{
        boxShadow: '0', // Remove a sombra padrão
        backdropFilter: 'blur(24px)', // Aplica um efeito de desfoque no fundo
      }}
    >
      {/* Barra de ferramentas que contém os elementos da navegação */}
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between', // Espaça os itens igualmente
          mx: { xs: 0, lg: 7 }, // Margens laterais variáveis por tamanho de tela
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <ZyvoxiIcon
            sx={{ fontSize: '4em', maxHeight: '50px', maxWidth: '50px' }}
          />
          {/* Texto do logo ou nome que redireciona para o GitHub */}
          <Box>
            <TextButton
              variant='h5'
              onClick={() =>
                window.open(
                  'https://github.com/zyvoxi',
                  '_blank',
                  'noopener,noreferrer',
                )
              }
            >
              Zyvoxi
            </TextButton>
          </Box>
        </Box>
        {/* Botões de navegação para telas grandes */}
        <Stack
          direction={'row'}
          spacing={1}
          display={{ xs: 'none', lg: 'flex' }} // Esconde em telas pequenas
        >
          {Buttons.map((button) => (
            <StyledButton
              key={button.id}
              variant='text'
              onClick={() =>
                // Chama a função de rolagem para a seção correspondente
                scrollToSection(
                  button.ref === 'helloRef'
                    ? helloRef
                    : button.ref === 'aboutRef'
                      ? aboutRef
                      : button.ref === 'skillsRef'
                        ? skillsRef
                        : projectsRef,
                )
              }
            >
              {button.label}
            </StyledButton>
          ))}
        </Stack>
        {/* Botão que abre o menu lateral em telas pequenas */}
        <Box display={{ xs: 'block', lg: 'none' }}>
          <StyledIconButton
            component='span'
            onClick={() => toggleDrawer()}
            aria-label='Abrir o menu'
          >
            <MenuIcon sx={{ color: '#fff' }} /> {/* Ícone do menu */}
          </StyledIconButton>
        </Box>
        {/* Componente Drawer que exibe o menu lateral em dispositivos móveis */}
        <Drawer
          anchor='top' // O menu desliza a partir do topo
          open={open} // Controla se o menu está aberto ou fechado
          onClose={() => toggleDrawer()} // Fecha o menu ao clicar fora
          sx={{
            '--Drawer-transitionDuration': '0.25s',
            '--Drawer-transitionFunction': 'cubic-bezier(0.4, 0, 0.25, 0.6)',
            '& .MuiBox-root': {
              bgcolor: 'transparent', // Fundo transparente
            },
            '& .MuiDrawer-backdrop': {
              bgcolor: '#0005', // Fundo semitransparente
              backdropFilter: 'blur(1px)', // Leve desfoque
            },
            '& .MuiDrawer-content': {
              bgcolor: '#f3f4ff04', // Cor de fundo personalizada
              backdropFilter: 'blur(10px)', // Aplica desfoque
              height: 'auto',
              boxShadow:
                'rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px, rgba(0, 0, 0, 0.12) 0px 6px 30px 5px',
            },
          }}
        >
          {/* Conteúdo do menu lateral */}
          <Box sx={{ px: 1.5, py: 1, backgroundColor: 'background.default' }}>
            {/* Botão para fechar o menu */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
              }}
            >
              <Box mx={0.5}>
                <StyledIconButton
                  component='span'
                  onClick={() => toggleDrawer()}
                  aria-label='Fechar o menu'
                >
                  {/* Ícone de fechar */}
                  <CloseRoundedIcon sx={{ color: '#fff' }} />
                </StyledIconButton>
              </Box>
            </Box>
            <Divider sx={{ m: 1, bgcolor: '#fffc' }} /> {/* Linha divisória */}
            {/* Itens do menu */}
            {Buttons.map((button) => (
              <StyledMenuItem
                key={button.id}
                onClick={() => {
                  // Chama a função de rolagem para a seção correspondente
                  scrollToSection(
                    button.ref === 'helloRef'
                      ? helloRef
                      : button.ref === 'aboutRef'
                        ? aboutRef
                        : button.ref === 'skillsRef'
                          ? skillsRef
                          : projectsRef,
                  );
                  toggleDrawer(); // Fecha o menu após a seleção
                }}
              >
                {button.label}
              </StyledMenuItem>
            ))}
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}

// Define os tipos e requisitos das propriedades recebidas pelo componente
AppAppBar.propTypes = {
  helloRef: PropTypes.object.isRequired,
  aboutRef: PropTypes.object.isRequired,
  skillsRef: PropTypes.object.isRequired,
  projectsRef: PropTypes.object.isRequired,
  scrollToSection: PropTypes.func.isRequired,
};
