import * as React from 'react';
import { Box, Typography, Container, styled } from '@mui/material';

// Cria um componente de texto estilizado utilizando o 'styled' do Material UI
const Text = styled(Typography)({
  color: 'white', // Define a cor do texto como branco
  fontWeight: 400, // Define o peso da fonte como normal
  letterSpacing: '.15em', // Define o espaçamento entre as letras
  whiteSpace: 'pre-wrap', // Mantém espaços e quebras de linha
});

export default function About() {
  // Componente funcional 'About' que será exportado como padrão
  return (
    <Box
      // Componente Box que serve como contêiner principal
      sx={{
        pt: 10, // Padding-top de 10 unidades
        height: '100%', // Altura igual a 100% do contêiner pai
        width: '100%', // Largura igual a 100% do contêiner pai
      }}
    >
      <Text
        // Componente de texto personalizado
        variant='h4' // Variante tipográfica h4
        align='center' // Alinha o texto ao centro
        sx={{
          fontWeight: 700, // Peso da fonte (negrito)
          fontSize: { xs: '2.15em', sm: '2.35em' }, // Tamanho da fonte responsivo para diferentes tamanhos de tela
        }}
      >
        Sobre
        {/* Texto exibido no componente */}
      </Text>
      <Container
        maxWidth={'xl'}
        sx={{
          mt: { xs: 7, sm: 5 }, // Margem superior: 7 unidades em telas pequenas, 5 unidades em telas médias
          display: 'flex', // Define o layout flexível
          flexDirection: 'column', // Direção dos itens em coluna
          gap: 6, // Espaçamento entre os itens
          textAlign: { xs: 'center', lg: 'justify' }, // Alinhamento do texto: centralizado em telas pequenas, justificado em telas grandes
        }}
      >
        <Text variant='h5'>
          {/* Primeiro parágrafo de texto */}
          Sou um desenvolvedor Front-End que encontrou na programação uma paixão
          que transformou minha forma de enxergar o mundo digital. Minha jornada
          começou durante a pandemia, quando comecei a explorar a programação
          como uma forma de criar soluções inovadoras.
        </Text>
        <Text variant='h5'>
          {/* Segundo parágrafo de texto */}
          No início, mergulhei nos fundamentos básicos, aprendendo HTML e CSS
          para criar páginas simples. Contudo, foi em meados de 2023 que decidi
          me aprofundar de verdade no universo da programação, dedicando-me a
          explorar novas linguagens e ferramentas.
        </Text>
        <Text variant='h5'>
          {/* Terceiro parágrafo de texto */}
          Cada linha de código que escrevo é o resultado de curiosidade,
          dedicação e aprendizado contínuo. Ao longo do caminho, enfrentei
          desafios técnicos que não só me ensinaram a importância de aprender
          constantemente, mas também a equilibrar estética e funcionalidade no
          desenvolvimento web. Esses desafios me motivam a buscar soluções
          criativas e eficientes, enquanto cada projeto, seja grande ou pequeno,
          contribui para a minha evolução como desenvolvedor, ajudando-me a
          entender melhor as necessidades dos usuários e a criar experiências
          cada vez mais impactantes.
        </Text>
        <Text variant='h5'>
          {/* Quarto parágrafo de texto */}
          Hoje, meu objetivo é continuar evoluindo como desenvolvedor e explorar
          novas tecnologias. Este portfólio é uma forma de compartilhar minha
          trajetória e meu trabalho — e também um convite para você conhecer
          mais sobre o que eu faço.
        </Text>
      </Container>
    </Box>
  );
}
