import * as React from 'react';
import { Box, Typography, styled } from '@mui/material';

// Cria um componente de texto estilizado utilizando o 'styled' do Material UI
const Text = styled(Typography)({
  color: 'white', // Define a cor do texto como branco
  fontWeight: 400, // Define o peso da fonte como normal
  letterSpacing: '.15em', // Define o espaçamento entre as letras
  whiteSpace: 'pre-wrap', // Mantém espaços e quebras de linha
});

export default function Contact() {
  // Componente funcional 'About' que será exportado como padrão
  return (
    <Box
      // Componente Box que serve como contêiner principal
      sx={{
        pt: 2, // Padding-top de 10 unidades
        height: '100%', // Altura igual a 100% do contêiner pai
        width: '100%', // Largura igual a 100% do contêiner pai
      }}
    >
      <Text
        // Componente de texto personalizado
        variant='h4' // Variante tipográfica h4
        align='center' // Alinha o texto ao centro
        sx={{
          fontWeight: 700, // Peso da fonte (negrito)
          fontSize: { xs: '2.15em', sm: '2.35em' }, // Tamanho da fonte responsivo para diferentes tamanhos de tela
        }}
      >
        Contate-me
        {/* Texto exibido no componente */}
      </Text>
      <Box
        // Outro contêiner Box para o conteúdo principal
        sx={{
          mx: { xs: 3, lg: 14 }, // Margens horizontais: 3 unidades em telas pequenas, 14 unidades em telas grandes
          mt: { xs: 7, sm: 5 }, // Margem superior: 7 unidades em telas pequenas, 5 unidades em telas médias
          display: 'flex', // Define o layout flexível
          flexDirection: 'column', // Direção dos itens em coluna
        }}
      ></Box>
    </Box>
  );
}
